import { useHubble as useHubbleUtil } from 'shared-utils/hooks';
import { Button, ButtonGroup, Text, useToast } from 'ui';

import { useIntercom } from '@/context/IntercomContext/IntercomContext';

import { environment } from '../../utils/environment-utils';

import type { FC } from 'react';
import type { HubbleSurveys } from 'shared-utils/hooks';

type useHubbleProps = {
  survey: HubbleSurveys;
};

const ToastContent: FC = () => {
  const Intercom = useIntercom();
  return (
    <>
      <Text mb="3">
        This may be caused by an ad blocker. If you have an ad blocker enabled, try disabling it or contact support
        instead.
      </Text>
      <ButtonGroup>
        <Button onClick={() => window.location.reload()} bgColor="yellow.100">
          Refresh
        </Button>
        <Button onClick={() => Intercom.showMessages()} bgColor="yellow.100">
          Contact Support
        </Button>
      </ButtonGroup>
    </>
  );
};

export function useHubbleWrapper() {
  const { isProductionBuild, isProductionDeployment } = environment;
  const hubble = useHubbleUtil();
  const toast = useToast();

  const show = (props: useHubbleProps) => {
    const { survey } = props;
    try {
      hubble.show(survey);
    } catch {
      // If we're on a build then there's a reasonable chance it's an ad blocker
      if (isProductionDeployment || isProductionBuild) {
        toast({
          title: 'Form unavailable',
          description: <ToastContent />,
          status: 'warning',
          duration: null,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  return {
    show,
  };
}
