import { useFeatureFlags } from 'feature-flags';
import jwtDecode from 'jwt-decode';

import { TypedStorage } from '@/utils/TypedStorage';

export function useIsKinde() {
  const viewerJwt = TypedStorage.get('viewer_jwt');
  const { KINDE_AUTH } = useFeatureFlags(['KINDE_AUTH'], { application: 'sessions' });

  if (viewerJwt) {
    const decodedJwt = jwtDecode(viewerJwt) as { exp?: number };
    // We use askable token even kinde flag is on until askable token expires.
    if (decodedJwt?.exp && decodedJwt?.exp > Date.now() / 1000) {
      return false;
    }

    TypedStorage.remove('viewer_jwt');
  }
  return KINDE_AUTH;
}
