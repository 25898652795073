import { Button } from '@askable/ui/components/ui/button';
import { Link } from '@askable/ui/components/ui/link';
import { useTranslation, Trans } from 'react-i18next';

import { useIntercom } from '@/context/IntercomContext/IntercomContext';

const Error = ({
  title,
  message,
  showHomeLink,
  showRefreshLink = true,
  linkTo,
  children,
}: {
  title?: string;
  message?: string;
  showHomeLink?: boolean;
  showRefreshLink?: boolean;
  linkTo?: string;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const intercom = useIntercom();
  const reset = () => window.location.reload();
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="max-w-1/2">
        <div className="flex flex-col items-center space-y-6">
          <div className="flex flex-col items-start gap-4 space-y-2 lg:items-center lg:text-center">
            <h1 id="heading" className="text-2xl font-semibold">
              {title ?? t('errors.default')}
            </h1>
            <p className="text-md max-w-96 font-normal">
              {message ?? (
                <Trans i18nKey="errors.defaultInstructions">
                  Please try refreshing and joining the call again. If the issue persists, please
                  <Button
                    className="text-md inline-flex p-0 font-normal"
                    variant="link"
                    onClick={() => intercom.show()}
                  >
                    contact support
                  </Button>
                  .
                </Trans>
              )}
            </p>
            {children}
          </div>
          {showHomeLink ? <Link href={linkTo ?? '/'}>{t('global.returnHome')}</Link> : null}
          {showRefreshLink ? (
            <Button variant="primary" onClick={() => reset()}>
              {t('global.refresh')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Error;
