import { analytics } from '@/src/analytics';

import type { EventType } from '@repo/analytics/src/Analytics';

export const useSegment = () => {
  function trackEvent(event: EventType, properties = {}) {
    analytics.track(event, properties);
  }

  return {
    trackEvent,
  };
};
